import React, { useEffect, useState } from "react";
import { CircularProgress, List, ListItemButton, Menu } from "@mui/material";
import { Rings } from "react-loader-spinner";
import { CheckMark, LogoIcon, SortIcon, Plus } from "icons";
import { SESSION_AUTH } from "common/auth";
import { toast } from "react-toastify";
import { CallApi } from "api/apis";

const HeaderAccounts = (props) => {
  const { setLoginVerify } = props;
  const { isUserSession, UPDATE_USER_SESSION, LOGOUT_USER_SESSION } =
    SESSION_AUTH();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [headerMenuEl, setHeaderMenuEl] = useState(null);
  const headerMenuOpen = Boolean(headerMenuEl);
  const [userAccountsList, setUserAccountsList] = useState(null);

  const headerMenuOpenReq = (event) => {
    if (!userData?.isVerify) {
      setLoginVerify(true);
      return;
    }
    setHeaderMenuEl(event.currentTarget);
    if (!userAccountsList) getUserAccountsList();
  };
  const headerMenuClose = () => {
    setHeaderMenuEl(null);
  };

  useEffect(() => {
    if (isUserSession) {
      setUserData(isUserSession);
    } else {
      setUserData("");
      setUserAccountsList("");
    }
    return () => null;
  }, [isUserSession]);

  if (!isUserSession) {
    return;
  }
  const getUserAccountsList = () => {
    try {
      CallApi(
        {
          userId: userData?.userId,
          org: userData?.org?.id,
        },
        `api/v1/account/list`
      )
        .then((data) => {
          if (data.code === 200) {
            if (data?.data && data?.data?.length > 0) {
              setUserAccountsList(data?.data);
            }
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            toast(data.message);
            setHeaderMenuEl(null);
          }
        })
        .catch((err) => {
          toast(err.message);
        });
    } catch (err) {
      toast(err.message);
    }
  };

  const switchUserAccount = (item) => {
    if (item?.accountId === userData?.org?.id) return;
    setLoading(true);
    headerMenuClose();
    CallApi(
      {
        userId: userData?.userId,
        org: userData?.org?.id,
        switch: item?.accountId,
      },
      `api/v1/account/switch`
    )
      .then((data) => {
        if (data.code === 200) {
          UPDATE_USER_SESSION({ org: data?.data });
        } else if (data.code === 600) {
          LOGOUT_USER_SESSION();
        } else {
          toast(data.message);
        }
      })
      .catch((error) => {
        toast(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createUserOrgAccount = () => {
    try {
      setLoading(true);
      headerMenuClose();
      CallApi(
        {
          userId: userData?.userId,
          org: userData?.org?.id,
        },
        `api/v1/account/create`
      )
        .then((data) => {
          if (data.code === 200) {
            setUserAccountsList("");
            switchUserAccount(data.data);
            return;
          } else if (data.code === 600) {
            LOGOUT_USER_SESSION();
          } else {
            toast(data.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          toast(err.message);
          setLoading(false);
        });
    } catch (err) {
      toast(err.message);
    }
  };
  return (
    <>
      {loading ? (
        <Rings
          height="80"
          width="80"
          color="#fff"
          radius="6"
          wrapperStyle={{}}
          wrapperClass="sixAiLoader"
          visible={true}
          ariaLabel="rings-loading"
        />
      ) : null}
      <div
        className={`sixai-users-features-item-accounts-btn ${
          headerMenuOpen ? "active" : ""
        }`}
        id="header-accounts-menu-button"
        aria-controls={headerMenuOpen ? "header-accounts-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={headerMenuOpen ? "true" : undefined}
        onClick={headerMenuOpenReq}
      >
        <div
          className="sixai-users-features-item-accounts-btn-logo"
          style={{
            backgroundColor: userData?.org?.orgLogo
              ? userData?.org?.orgLogoColor
              : "",
          }}
        >
          {userData?.org?.orgLogo ? (
            <img src={userData?.org?.orgLogo} alt="logo" />
          ) : (
            <LogoIcon />
          )}
        </div>
        <div
          className="sixai-users-features-item-accounts-btn-name"
          translate="no"
        >
          {userData?.org?.orgName}
        </div>
        <div className="sixai-users-features-item-accounts-btn-sort">
          <SortIcon />
        </div>
      </div>

      <Menu
        id="header-accounts-menu"
        anchorEl={headerMenuEl}
        open={headerMenuOpen}
        onClose={headerMenuClose}
        MenuListProps={{
          "aria-labelledby": "header-accounts-menu-button",
        }}
        className={`sixai-users-features-item-dropdown sixai-users-features-item-accounts`}
      >
        <List
          component="nav"
          className="sixai-users-features-item-accounts-list"
        >
          {userAccountsList ? (
            userAccountsList?.map((item, key) => (
              <ListItemButton
                key={key}
                className="sixai-users-features-item-accounts-list-item"
                onClick={() => {
                  switchUserAccount(item);
                }}
                title={item?.orgName}
              >
                <div className="sixai-users-features-item-accounts-list-item-logo">
                  {item?.orgLogo ? (
                    <img src={item.orgLogo} alt="logo" />
                  ) : (
                    <LogoIcon />
                  )}
                </div>
                <div
                  className="sixai-users-features-item-accounts-list-item-name"
                  translate="no"
                >
                  {item?.orgName}
                </div>
                {item.accountId === userData?.org?.id && (
                  <div className="sixai-users-features-item-accounts-list-item-check">
                    <CheckMark />
                  </div>
                )}
              </ListItemButton>
            ))
          ) : (
            <div className="sixai-users-features-item-accounts-list-loader">
              <CircularProgress />
            </div>
          )}
        </List>
        {userAccountsList ? (
          <div
            onClick={createUserOrgAccount}
            className="sixai-users-features-item-accounts-footer"
          >
            <Plus /> Create Org
          </div>
        ) : null}
      </Menu>
    </>
  );
};

export default HeaderAccounts;
