/* global google */
import { useState, useEffect, useRef } from "react";
import parse from "html-react-parser";

import TextareaAutosize from "react-textarea-autosize";
import { marked } from "marked";
import * as DOMPurify from "dompurify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useLocation } from "react-router-dom";
import $ from "jquery";
import "./chatgpt.scss";
import logos from "../../assets/images/logos.png";
import send from "../../assets/images/send.svg";
import attachmentIcon from "../../assets/images/icons/attachnent.svg";
import loadergif from "../../assets/images/loadergif.gif";
import message_icon_gold from "../../assets/images/message-square-gold.svg";
import message_icon from "../../assets/images/message-square.svg";
import trash_icon from "../../assets/images/trash.svg";
import share_icon2 from "../../assets/images/share.svg";
import more from "../../assets/images/more-vertical.svg";
import more_alt from "../../assets/images/more-vertical-alt.svg";

import edit_icon from "../../assets/images/edit.svg";

import copy from "../../assets/images/copy.svg";
import pdf from "../../assets/images/pdf.svg";
import file_text from "../../assets/images/file-text.svg";

import rotate_icon from "../../assets/images/rotate.svg";

import right_icon from "../../assets/images/right_icon.svg";
import Sharedpopup from "./6ixai_popup/Sharedpopup";
import Deletepopup from "./6ixai_popup/Deletepopup";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { CallApi } from "../../api/apis";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/esm/Button";
import { exportResponse, exportWholeChat } from "utils/downloader";
import { useReactToPrint } from "react-to-print";
// @ts-ignore
import html2pdf from "html2pdf.js/dist/html2pdf.min.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { LoginPopup } from "components/Account/Login/LoginPopup";
import { signInWithCredentialResponse } from "../../firebase";
import { ToastContainer, toast } from "react-toastify";
import { SubscriptionPopup } from "components/Account/Subscription/SubscriptionPopup";

import { Rings } from "react-loader-spinner";
import Cookies from "js-cookie";
import { usePostHog } from "posthog-js/react";
import { UserLogin, MenuIcon } from "icons";

import {
  API_URL,
  SET_PUBLIC_SESSION,
  GET_PUBLIC_SESSION,
  REMOVE_PUBLIC_SESSION,
} from "../../common/constants";
import { SESSION_AUTH } from "common/auth";
import HeaderUsers from "components/Header/HeaderUser";
import HeaderApps from "components/Header/HeaderApps";
import HeaderAccounts from "components/Header/HeaderAccounts";
import HeaderLogo from "components/Header/HeaderLogo";

let messagesRes;
let scrollAllow = true;

marked.setOptions({
  xhtml: true,
  silent: true,
  gfm: true,
  breaks: false,
  pedantic: true,
});

const Chatgpt = () => {
  const location = useLocation();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const Q_AI = params.get("q_ai");

  const { isUserSession, SET_USER_SESSION, LOGOUT_USER_SESSION } =
    SESSION_AUTH();
  const posthog = usePostHog();
  const componentRef = useRef([]);
  const dataFetched = useRef(false);
  const [currentChatIndex, setCurrentChatIndex] = useState(0);
  const scroll_top = useRef();
  const search_button = useRef();
  const [user_loggedein, setUser_loggedein] = useState("");
  const [inputActive, setInputActive] = useState("");
  const [active, setActive] = useState(false);
  const [reading, setReading] = useState(false);
  const [delete_popup, setDelete_popup] = useState(false);
  const [emailsid, setEmailsid] = useState([]);
  const [Topicdata, setTopicdata] = useState();
  const [SharedTopicdata, setSharedTopicdata] = useState();
  const [edittopicvalue, setEdittopicvalue] = useState("");
  const [popup, setPopup] = useState(false);
  const [withtopic, setWithtopic] = useState();
  const [deleteids, setDeleteids] = useState();
  const [count, setCount] = useState(-1);
  const [submit, setSubmit] = useState(false);
  const [hidechats, setHidechats] = useState(false);
  const [responsedata, setResponsedata] = useState([]);
  const [topicid, setTopicid] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [hideicon, setHideicon] = useState(false);
  const [chatcontent, setChatcontent] = useState([]);
  const [message, setMessage] = useState(Q_AI || "");
  const [Q_AIMessage, setQ_AIMessage] = useState(Q_AI || "");
  const [mobSidebar, setMobSidebar] = useState(true);
  const [regenratedresponse, setRegenratedresponse] = useState(false);
  const [responseProcessLevel, setResponseProcessLevel] = useState(0);
  const [showStopbtn, setShowstopbtn] = useState(false);
  const [regenerateMessage, setRegenerateMessage] = useState();

  const [responseNumber, setResponseNumber] = useState(0);
  const [loginRequest, setLoginRequest] = useState(false);
  const [loader, setLoader] = useState(false);

  const [controller, setController] = useState(null);

  const [openSubscriptionPopup, setOpenSubscriptionPopup] = useState(false);
  const [searchBy, setSearchBy] = useState("ai");

  const [subscriptionUpgrade, setSubscriptionUpgrade] = useState("");

  useEffect(() => {
    if (Q_AIMessage) {
      setQ_AIMessage("");
      handleSubmit();
    }
  }, [Q_AIMessage]);

  const onChangeSearchBy = (selectedOption) => {
    setSearchBy(selectedOption?.target?.value);
  };

  useEffect(() => {
    if (isUserSession) {
      setUser_loggedein(isUserSession);
    } else {
      setUser_loggedein("");
      initializeGSI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserSession]);

  useEffect(() => {
    if (topicid && Topicdata) {
      let t = Topicdata.filter(function (itm) {
        return itm.id === topicid;
      });

      setSelectedTopic(t?.[0] || "");
    } else {
      setSelectedTopic("");
    }
  }, [topicid, Topicdata]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current[currentChatIndex],
    copyStyles: true,
    removeAfterPrint: true,
    bodyClass: "print",
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      var opt = {
        margin: 10,
        filename: `response.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.1 },
        jsPDF: {
          unit: "mm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          floatPrecision: 12,
        },
        enableLinks: false,
        pagebreak: { mode: ["avoid-all"] },
      };
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        html2pdf().set(opt).from(html).save();
      }
    },
  });

  const handlePrintCompleteChat = useReactToPrint({
    content: () => scroll_top.current,
    copyStyles: true,
    removeAfterPrint: true,
    bodyClass: "print",
    print: async (printIframe) => {
      const document = printIframe.contentDocument;
      var opt = {
        margin: 10,
        filename: `response.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.1 },
        jsPDF: {
          unit: "mm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          floatPrecision: 12,
        },
        enableLinks: false,
        pagebreak: { mode: ["avoid-all"] },
      };
      if (document) {
        const html = document.getElementsByTagName("html")[0];
        html2pdf().set(opt).from(html).save();
      }
    },
  });

  const initializeGSI = () => {
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
      cancel_on_tap_outside: true,
      callback: loginWithWithCredential,
    });

    google.accounts.id.prompt();
  };

  const loginWithWithCredential = async (response) => {
    setLoader(true);
    signInWithCredentialResponse(response)
      .then((data) => {
        if (data?.code === 200 && data?.data) {
          SET_USER_SESSION(data?.data);
          setLoginRequest(false);
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoader(false);
      });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Login to unlock all 6ix AI features
    </Tooltip>
  );

  const popperConfig = {
    strategy: "fixed",
  };

  const requestWithLatestMessage = async () => {
    if (message !== "" || regenratedresponse) {
      if (!user_loggedein) {
        let searchAccess = parseInt(Cookies.get("searchAccess")) || 0;
        searchAccess = searchAccess + 1;

        if (searchAccess > 5) {
          toast(
            "Unlock unlimited searches: Login to discover more with 6ix AI!"
          );
          setMessage("");
          handleLogin();
          return;
        }
        Cookies.set("searchAccess", searchAccess, { expires: 365 });
      }

      setHideicon(true);
      setMessage("");
      setResponseProcessLevel(1);
      scroll();
      try {
        const newController = new AbortController();
        setController(newController);
        let requestMessageList = chatcontent; //chatcontent; //[...messageList()]
        const postdata = {
          query: requestMessageList,
        };

        if (selectedTopic && selectedTopic?.dataFrame?.length > 0) {
          postdata.dataFrame = [];
          for (let c = 0; c < selectedTopic?.dataFrame?.length; c++) {
            if (selectedTopic?.dataFrame?.[c]?.url)
              postdata.dataFrame.push(selectedTopic?.dataFrame?.[c]?.url);
          }
          postdata.searchType = selectedTopic?.searchType;
        } else {
          postdata.searchType = searchBy;
        }

        messagesRes = "";
        if (regenratedresponse) {
          let n = [...responsedata];
          n[n.length - 1] = [];
          setResponsedata((old) => [...old, ...n]);
        } else {
          let n = [[]];
          setResponsedata((old) => [...old, ...n]);
        }
        //setResponsedata(value);

        const response = await fetch(API_URL + "api/v1/chat/_a", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user_loggedein?.authToken,
          },
          method: "POST",
          body: JSON.stringify(postdata),
          signal: newController.signal,
        });
        if (!response.ok) {
          const error = await response.json();
          console.error(error.error);

          throw new Error("Request failed");
        }

        const data = response.body;

        if (!data) {
          if (regenratedresponse) {
            setLeatestResponse("No Result Generated !");
          } else {
            setLeatestResponse("No Result Generated !");
          }
          return;
        }

        // const reader =data.getReader()
        const reader = response.body?.getReader();

        const utf8decoder = new TextDecoder();

        let done = false;
        setReading(true);
        while (!done) {
          const { value, done: readerDone } = await reader.read();

          if (readerDone) {
            setRegenratedresponse(false);
            setReading(false);
            setResponseNumber((prev) => prev + 1);
            setHideicon(false);
            setShowstopbtn(false);
            setResponseProcessLevel(0);
            let lastsearchQuery = [...chatcontent].pop();
            if (user_loggedein) {
              updateConversation(lastsearchQuery?.content, messagesRes);
            } else if (lastsearchQuery?.content) {
              let today = new Date();
              SET_PUBLIC_SESSION(
                "aidraft",
                JSON.stringify({
                  content: lastsearchQuery?.content,
                  query: messagesRes,
                  time: today.toDateString(),
                })
              );
            }
            return;
          } else {
            if (value) {
              try {
                let char = utf8decoder.decode(value);

                if (char.indexOf("data: ") > -1) {
                  char = char.split("data: ");

                  for (let i = 0; i < char.length; i++) {
                    if (!char[i] || char[i].trim() === "[DONE]") {
                      continue;
                    }
                    let text;
                    try {
                      let json = JSON.parse(char[i]);
                      text = json?.choices?.[0]?.delta?.content || "";
                      if (json.choices?.[0].delta?.final_content) {
                        text = json.choices[0].delta?.final_content;
                        messagesRes = "";
                      }
                    } catch (err) {
                      //text = char[i];
                    }

                    if (text) {
                      messagesRes = messagesRes ? messagesRes + text : text;

                      if (regenratedresponse) {
                        setLeatestResponse(messagesRes);
                      } else {
                        setLeatestResponse(messagesRes);
                      }
                      setResponseProcessLevel(0);
                    }
                  }
                  if (scrollAllow) {
                    $(".response-area").animate({ scrollTop: +50000 }, 0);
                  }
                } else {
                  try {
                    let resData = JSON.parse(char);
                    if (resData?.code === 201) {
                      setOpenSubscriptionPopup(true);
                    } else if (resData?.code === 600) {
                      LOGOUT_USER_SESSION();
                    }
                  } catch (err) {}
                }
              } catch (e) {
                console.log(e);
              }
            }
          }
          setShowstopbtn(true);
          done = readerDone;
        }
      } catch (e) {
        console.error(e);

        setController(null);
        return;
      }
    }
  };

  const onSelectDataFrame = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    setLoader(true);

    try {
      const postdata = {
        id: topicid,
        attachment: e.target.files[0],
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };

      let res = await CallApi(
        postdata,
        `api/v1/verified/topics/attachment/add`,
        "multipart/form-data"
      );
      if (res.code === 200) {
        setTopicid(res?.data.topic?.id);
        setTopicdata([...Topicdata, res?.data?.topic]);
        getTopics();
        if (res?.data?.topicsNew) {
          if (res?.data?.topic?.searchType === "CSV") {
            setMessage(
              `Provide a description of the columns, and data, and provide your best interpretation about what ${res?.data?.filename} file is about`
            );
          } else {
            //setMessage(`Title of  the ${res?.data?.filename} `);
            setMessage(
              "Provide a brief summary of the content of the article, along with one unique insight based on the content"
            );
          }

          search_button.current.click();
        }
      } else if (res?.code === 201) {
        setOpenSubscriptionPopup(true);
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      } else {
        toast(res?.message);
      }
    } catch (err) {
      toast("Something went wrong");
    }
    setLoader(false);
  };
  const updateConversation = async (chatcontent, messages, n) => {
    try {
      let postdata = {
        query: chatcontent,
        topicID: topicid,
        messages: messages,
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };

      let res = await CallApi(postdata, `api/v1/chat/_u`);

      if (res.code === 200) {
        setTopicid(res?.data?.topicID);
        if (res?.data?.topicsNew) {
          getTopics();
          SharedTopics();
          if (n) {
            getchatsbytopicid(res?.data?.topicID);
            setActive(res?.data?.topicID);
          }
        }
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      //console.log(err, "error update");
    }
  };

  const getallmessages = (regenratemessage, conf) => {
    if (
      (message !== "" &&
        message.trim().length !== 0 &&
        message !== undefined) ||
      (regenratemessage !== "" && regenratemessage !== undefined)
    ) {
      if (conf) {
        setRegenratedresponse(true);
        setChatcontent(chatcontent);
      } else {
        setChatcontent([
          ...chatcontent,
          { role: "user", content: message ? message : regenratemessage },
        ]);
      }
    }
  };

  const handleScroll = () => {
    if (scroll_top.current) {
      const { scrollTop, scrollHeight, clientHeight } = scroll_top.current;
      if (scrollTop + clientHeight >= scrollHeight) {
        scrollAllow = true;
      } else {
        scrollAllow = false;
      }
    }
  };

  function scroll() {
    $(".response-area").animate({ scrollTop: +50000 });
  }

  const getTopics = () => {
    try {
      const postdata = {
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      CallApi(postdata, `api/v1/verified/topics/get`)
        .then((res) => {
          if (res.code === 200) {
            setTopicdata(res?.data);
          } else if (res.code === 600) {
            LOGOUT_USER_SESSION();
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    } catch (err) {
      console.log(err, "err");
    }
  };
  const editTopics = async (id) => {
    try {
      const postdata = {
        id: id,
        name: edittopicvalue,
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      let res = await CallApi(postdata, `api/v1/verified/topics/edit`);
      if (res.code === 200) {
        getTopics();
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const deleteTopics = async () => {
    try {
      const postdata = {
        id: deleteids,
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      let res = await CallApi(postdata, `api/v1/verified/topics/delete`);
      if (res.code === 200) {
        getTopics();
        setDeleteids();
        setWithtopic();
        setMessage("");
        setTopicid("");
        setResponsedata([]);
        setChatcontent([]);
        setActive("");
        setHidechats(false);
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const clearTopics = async () => {
    try {
      const postdata = {
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      let res = await CallApi(postdata, `api/v1/verified/topics/clear`);
      if (res.code === 200) {
        getTopics();
        setMessage("");
        setTopicid("");
        setWithtopic();
        setResponsedata([]);
        setChatcontent([]);
        setActive("");
        setHidechats(false);
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const SharedTopics = async () => {
    try {
      const postdata = {
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      let res = await CallApi(postdata, `api/v1/verified/topics/getShared`);
      if (res.code === 200) {
        setSharedTopicdata(res?.data);
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const clearsharedconversation = async () => {
    try {
      const postdata = {
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      let res = await CallApi(postdata, `api/v1/verified/topics/clearShared`);
      if (res.code === 200) {
        SharedTopics();
        setWithtopic();
        setMessage("");
        setTopicid("");
        setResponsedata([]);
        setChatcontent([]);
        setActive("");
        setHidechats(false);
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const getchatsbytopicid = async (id) => {
    try {
      const postdata = {
        id: id,
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      let res = await CallApi(
        postdata,
        `api/v1/verified/topics/getTopicHistory`
      );
      if (res.code === 200) {
        setTopicid(res?.data?.topicID);
        setResponsedata(res?.data?.answer);
        setChatcontent(res?.data?.question);
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const deleteSharedTopics = async () => {
    try {
      const postdata = {
        id: deleteids,
        userId: user_loggedein?.userId,
        org: user_loggedein?.org?.id,
      };
      let res = await CallApi(postdata, `api/v1/verified/topics/deleteShared`);
      if (res.code === 200) {
        SharedTopics();
        setDeleteids();
        setWithtopic();
        setMessage("");
        setTopicid("");
        setResponsedata([]);
        setChatcontent([]);
        setActive("");
        setHidechats(false);
      } else if (res.code === 600) {
        LOGOUT_USER_SESSION();
      }
    } catch (err) {
      console.log(err, "err");
    }
  };
  const handleLogin = () => {
    setLoginRequest(!loginRequest);
  };

  const handleAbortClick = () => {
    setReading(false);
    setResponseNumber((prev) => prev + 1);
    if (controller) {
      controller.abort();
    }
  };
  const SharedEmail = async () => {
    if (emailsid?.length) {
      try {
        const postdata = {
          id: topicid,
          emails: emailsid,
          userId: user_loggedein?.userId,
          org: user_loggedein?.org?.id,
        };
        let res = await CallApi(postdata, `api/v1/verified/topics/share`);
        if (res.code === 200) {
          setEmailsid([]);
          setTopicid("");
          getTopics();
          SharedTopics();
        } else if (res.code === 600) {
          LOGOUT_USER_SESSION();
        }
      } catch (err) {
        console.log(err, "err");
      }
    }
  };

  useEffect(() => {
    if (submit) {
      SharedEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit]);
  useEffect(() => {
    if (
      (message !== "" && message !== undefined && chatcontent?.length > 0) ||
      regenratedresponse
    ) {
      requestWithLatestMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatcontent, regenratedresponse]);

  useEffect(() => {
    if (user_loggedein && !dataFetched?.current) {
      dataFetched.current = true;
      let aiDraft = GET_PUBLIC_SESSION("aidraft");
      aiDraft = aiDraft ? JSON.parse(aiDraft) : null;
      REMOVE_PUBLIC_SESSION("aidraft");
      let today = new Date();
      if (
        aiDraft &&
        aiDraft?.content &&
        aiDraft?.query &&
        today.toDateString() === aiDraft?.time
      ) {
        updateConversation(aiDraft?.content, aiDraft?.query, true);
      } else {
        getTopics();
        SharedTopics();
      }

      posthog?.identify(user_loggedein.userId, {
        name: user_loggedein.name,
        email: user_loggedein.email,
      });
    } else {
      dataFetched.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user_loggedein]);
  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (message.trim().length !== 0 && !responseProcessLevel) {
      getallmessages();
      setCount(count + 1);
      scroll();
    }
  };

  useEffect(() => {
    if (regenerateMessage) {
      handleSubmit();
      setRegenerateMessage("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regenerateMessage]);

  useEffect(() => {
    $(".sixai-searchbox-input").focus();
    $(".react-tagsinput-input").attr(
      "placeholder",
      "Enter email and press Enter"
    );
    $(".react-tagsinput-input").prop("type", "email");
  }, []);

  const [leatestResponse, setLeatestResponse] = useState("");
  useEffect(() => {
    if (leatestResponse) {
      let old = [...responsedata];
      old[old.length - 1] = [leatestResponse];
      //console.log(leatestResponse, "responsedata");

      setResponsedata(old);
    }
    // eslint-disable-next-line
  }, [leatestResponse]);

  return (
    <>
      <ToastContainer
        closeOnClick={true}
        hideProgressBar={true}
        autoClose={3000}
        theme={"dark"}
        position="bottom-left"
      />
      {loader ? (
        <Rings
          height="80"
          width="80"
          color="#fff"
          radius="6"
          wrapperStyle={{}}
          wrapperClass="sixAiLoader"
          visible={true}
          ariaLabel="rings-loading"
        />
      ) : null}
      {!user_loggedein && loginRequest ? (
        <LoginPopup dropOut={true} cancel={true} handleLogin={handleLogin} />
      ) : null}
      <div className="sixai">
        <div className="sixai-chats-modals-header justify-content-center">
          <div className="sixai-chats-modals-header-left">
            <div
              className="sixai-chats-modals-header-menuButton"
              onClick={() => setMobSidebar(!mobSidebar)}
            >
              <MenuIcon close={!mobSidebar} />
            </div>
            <div className="sixai-chats-modals-header-lineV"></div>

            <HeaderLogo />
            <HeaderApps />
          </div>
          <div className="sixai-users-features ">
            {user_loggedein?.org && (
              <>
                <div className="sixai-users-features-item">
                  <HeaderAccounts />
                </div>
                <div className="sixai-users-features-lineV"></div>
              </>
            )}

            <div className="sixai-users-features-item">
              {user_loggedein ? (
                <HeaderUsers />
              ) : (
                <div
                  className="sixai-users-features-item-btn"
                  onClick={handleLogin}
                >
                  <UserLogin />
                  <div className="sixai-users-features-item-btn-text">
                    LOGIN
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="sixai-content">
          {!mobSidebar && (
            <div className={mobSidebar ? "sixai-users" : "sixai-users open"}>
              {/* new_chat button */}

              <div className="sixai-users-newchat d-flex align-item-center">
                <button
                  className="sixai-users-newchat-btn"
                  onClick={() => {
                    setMessage("");
                    setTopicid("");
                    setResponsedata([]);
                    setChatcontent([]);
                    setActive("");
                    setHidechats(false);
                    setMobSidebar(!mobSidebar);
                  }}
                >
                  <i className="fa fa-plus" aria-hidden="true"></i> New chat
                </button>
              </div>

              {/* sidebar */}

              <div className="sixai-users-title">
                <>
                  {/* Your Conversation */}
                  {Topicdata?.length ? (
                    <div className="sixai-users-title-my_conversation">
                      <p>Your conversation</p>
                      {Topicdata.map((item, key) => {
                        return (
                          <div
                            className="sixai-users-title-my_conversation-users_chat"
                            key={key}
                          >
                            <div
                              className="sixai-users-title-my_conversation-users_chat-inner d-flex align-item-center flex-grow-1"
                              onClick={() => {
                                setActive(item?.id);
                                setTopicid(item?.id);
                                setMessage("");
                                setHidechats(false);
                                getchatsbytopicid(item?.id);
                              }}
                            >
                              <img
                                src={
                                  active === item?.id
                                    ? message_icon_gold
                                    : message_icon
                                }
                                alt=""
                              />
                              {inputActive === item?.id ? (
                                <>
                                  <input
                                    className="title-input"
                                    defaultValue={item.name}
                                    onChange={(e) =>
                                      setEdittopicvalue(e.target.value)
                                    }
                                  />
                                </>
                              ) : (
                                <div>
                                  <p
                                    className={
                                      active === item?.id ||
                                      topicid === item?.id
                                        ? "sixai-users-title-my_conversation-users_chat-inner-title active"
                                        : "sixai-users-title-my_conversation-users_chatinner-title "
                                    }
                                  >
                                    {item.name}
                                  </p>
                                </div>
                              )}
                            </div>

                            {inputActive === item?.id ? (
                              <div
                                className="sixai-users-title-my_conversation-users_chat-updateicons justify-content-end"
                                style={{ gap: "12px" }}
                              >
                                <i
                                  className="fa fa-check"
                                  style={{ color: "#11B585" }}
                                  aria-hidden="true"
                                  onClick={() => {
                                    editTopics(item?.id);
                                    setInputActive();
                                  }}
                                ></i>
                                <i
                                  className="fa fa-times"
                                  style={{ color: "#EF2733" }}
                                  aria-hidden="true"
                                  onClick={() => {
                                    setInputActive();
                                  }}
                                ></i>
                              </div>
                            ) : (
                              <div className="sixai-users-title-my_conversation-users_chat-updateicons ">
                                <img
                                  src={share_icon2}
                                  alt=""
                                  onClick={() => {
                                    setPopup(true);
                                    setTopicid(item?.id);
                                  }}
                                />
                                <img
                                  src={edit_icon}
                                  alt=""
                                  onClick={() => {
                                    setInputActive(item?.id);
                                    setActive(item?.id);
                                  }}
                                />
                                <img
                                  src={trash_icon}
                                  onClick={() => {
                                    setDeleteids(item?.id);
                                    setDelete_popup(true);
                                    setWithtopic("deletetopic");
                                  }}
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                      <button
                        className="sixai-users-title-my_conversation-clearconversation"
                        onClick={() => {
                          setDelete_popup(true);
                          setWithtopic("cleartopic");
                        }}
                      >
                        <img src={trash_icon} alt="" />{" "}
                        <span>Clear conversations</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* Shared_conversation */}
                  {SharedTopicdata?.length ? (
                    <div className="sixai-users-title-shared_conversation">
                      <p>conversations Shared with you</p>
                      {SharedTopicdata.map((items, id2) => {
                        return (
                          <div
                            className="sixai-users-title-my_conversation-users_chat"
                            key={items?.id + "-" + id2}
                          >
                            <div
                              className="sixai-users-title-my_conversation-users_chat-inner d-flex align-item-center flex-grow-1"
                              onClick={() => {
                                setActive(items?.id);
                                setTopicid(items?.id);
                                setMessage("");
                                setHidechats(true);
                                getchatsbytopicid(items?.id);
                              }}
                            >
                              <img
                                src={
                                  active === items?.id
                                    ? message_icon_gold
                                    : message_icon
                                }
                                alt=""
                              />
                              <p
                                className={
                                  active === items?.id
                                    ? "sixai-users-title-my_conversation-users_chat-inner-title active"
                                    : "sixai-users-title-my_conversation-users_chatinner-title "
                                }
                              >
                                {items.name}
                              </p>
                            </div>
                            <div className="sixai-users-title-my_conversation-users_chat-updateicons">
                              <img
                                onClick={() => {
                                  setDeleteids(items?.id);
                                  setDelete_popup(true);
                                  setWithtopic("deleteshared");
                                }}
                                src={trash_icon}
                                alt=""
                              />
                            </div>
                          </div>
                        );
                      })}

                      <button
                        className="sixai-users-title-my_conversation-clearconversation"
                        onClick={() => {
                          setDelete_popup(true);
                          setWithtopic("clearshared");
                        }}
                      >
                        <img src={trash_icon} alt="" />{" "}
                        <span>Clear conversations</span>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </div>

              {/* sidebar_features */}
            </div>
          )}
          {/* sidebar_end */}
          <div className="sixai-chats">
            <div
              className={`sixai-chats-inner d-flex ${
                chatcontent?.length || topicid ? "has-chat" : "no-chat"
              }`}
            >
              <div className={"sixai-chats-inner-logo background-logo"}>
                <img src={logos} alt="" />
              </div>
              {/* show_grid */}
              {chatcontent?.length ? (
                <div className="response-area-outer">
                  <div
                    className="sixai-chats-inner response-area "
                    ref={scroll_top}
                    onScroll={handleScroll}
                  >
                    {chatcontent?.length
                      ? chatcontent.map((result, keys) => {
                          return (
                            <div
                              key={"qe" + keys}
                              ref={(el) => (componentRef.current[keys] = el)}
                            >
                              <div className="sixai-chats-inner-response d-flex align-item-center">
                                <div className="print-visible">Question:</div>
                                <div className="sixai-chats-inner-response-logo print-hidden">
                                  {user_loggedein?.profileImage ? (
                                    <img
                                      src={user_loggedein.profileImage}
                                      alt=""
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <span style={{ whiteSpace: "pre-wrap" }}>
                                  {result?.content}
                                </span>
                              </div>
                              <div
                                className="sixai-chats-inner-response sixai-response d-flex "
                                key={"re" + keys}
                              >
                                <div className="sixai-chats-inner-response-logo print-hidden">
                                  <img
                                    className="response-logo"
                                    src={logos}
                                    alt=""
                                  />
                                </div>
                                {responsedata[keys] ? (
                                  <>
                                    {responsedata[keys]?.map((res, rekey) => {
                                      return (
                                        <div
                                          key={"res-" + rekey}
                                          className="response-container"
                                        >
                                          {responseNumber !== keys && (
                                            <>
                                              {!user_loggedein ? (
                                                <OverlayTrigger
                                                  placement="top"
                                                  delay={{
                                                    show: 250,
                                                    hide: 400,
                                                  }}
                                                  overlay={renderTooltip}
                                                >
                                                  <Button
                                                    id="link-dropdown"
                                                    className={`drop-link disabled-item`}
                                                  >
                                                    <img
                                                      src={more_alt}
                                                      alt="dropdown"
                                                    />
                                                  </Button>
                                                </OverlayTrigger>
                                              ) : (
                                                <Dropdown
                                                  className="links-dropdown print-hidden"
                                                  autoClose={true}
                                                >
                                                  <Dropdown.Toggle
                                                    id="link-dropdown"
                                                    className={`drop-link`}
                                                  >
                                                    <img
                                                      src={more}
                                                      alt="dropdown"
                                                    />
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu
                                                    popperConfig={popperConfig}
                                                  >
                                                    <div className="items-holder">
                                                      <Dropdown.Item href="#">
                                                        <CopyToClipboard
                                                          text={
                                                            responsedata[keys][
                                                              rekey
                                                            ]
                                                          }
                                                        >
                                                          <span className="clip-holder">
                                                            <img
                                                              src={copy}
                                                              alt="copy icon"
                                                            />
                                                            Copy response
                                                          </span>
                                                        </CopyToClipboard>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#">
                                                        <button
                                                          onClick={() =>
                                                            exportResponse({
                                                              question:
                                                                chatcontent[
                                                                  responsedata.indexOf(
                                                                    responsedata[
                                                                      keys
                                                                    ]
                                                                  )
                                                                ].content,
                                                              answer:
                                                                responsedata[
                                                                  keys
                                                                ][rekey],
                                                            })
                                                          }
                                                        >
                                                          <img
                                                            src={file_text}
                                                            alt="text icon"
                                                          />
                                                          Download response
                                                          (TXT)
                                                        </button>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#">
                                                        <button
                                                          onClick={() => {
                                                            setCurrentChatIndex(
                                                              keys
                                                            );
                                                            handlePrint();
                                                          }}
                                                        >
                                                          <img
                                                            src={pdf}
                                                            alt="pdf icon"
                                                          />
                                                          Download response
                                                          (PDF)
                                                        </button>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#">
                                                        <button
                                                          onClick={() =>
                                                            exportWholeChat({
                                                              questions:
                                                                chatcontent,
                                                              answers:
                                                                responsedata,
                                                            })
                                                          }
                                                        >
                                                          <img
                                                            src={file_text}
                                                            alt="text icon"
                                                          />
                                                          Download complete
                                                          conversation (TXT)
                                                        </button>
                                                      </Dropdown.Item>
                                                      <Dropdown.Item href="#">
                                                        <button
                                                          onClick={
                                                            handlePrintCompleteChat
                                                          }
                                                        >
                                                          <img
                                                            src={pdf}
                                                            alt="pdf icon"
                                                          />
                                                          Download complete
                                                          conversation (PDF)
                                                        </button>
                                                      </Dropdown.Item>

                                                      {!reading && (
                                                        <Dropdown.Item href="#">
                                                          <button
                                                            className="d-flex justify-content-center align-item-center regenerate_response"
                                                            onClick={(e) => {
                                                              setRegenerateMessage(
                                                                chatcontent[
                                                                  responsedata.indexOf(
                                                                    responsedata[
                                                                      keys
                                                                    ]
                                                                  )
                                                                ].content
                                                              );
                                                              setMessage(
                                                                chatcontent[
                                                                  responsedata.indexOf(
                                                                    responsedata[
                                                                      keys
                                                                    ]
                                                                  )
                                                                ].content
                                                              );
                                                            }}
                                                          >
                                                            <img
                                                              src={rotate_icon}
                                                              alt=""
                                                            />
                                                            Regenerate response
                                                          </button>
                                                        </Dropdown.Item>
                                                      )}

                                                      {user_loggedein ? (
                                                        <>
                                                          <Dropdown.Item href="#">
                                                            <button
                                                              className={
                                                                showStopbtn
                                                                  ? "d-none"
                                                                  : "share-btn d-flex justify-content-center align-item-center"
                                                              }
                                                              onClick={() =>
                                                                setPopup(true)
                                                              }
                                                            >
                                                              <img
                                                                src={
                                                                  share_icon2
                                                                }
                                                                alt="share"
                                                              />
                                                              Share with friends
                                                            </button>
                                                          </Dropdown.Item>
                                                        </>
                                                      ) : null}
                                                    </div>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              )}
                                            </>
                                          )}
                                          <div className="print-visible">
                                            Answer:
                                          </div>

                                          {selectedTopic?.searchType ? (
                                            <div
                                              className="sixai-chats-inner-response-row"
                                              id={`sixai-chats-inner-response-${keys}-${rekey}`}
                                            >
                                              {parse(
                                                selectedTopic?.searchType
                                                  ? typeof res === "string"
                                                    ? res
                                                    : ""
                                                  : marked(
                                                      typeof res === "string"
                                                        ? res
                                                        : ""
                                                    )
                                              )}
                                            </div>
                                          ) : (
                                            <div
                                              className="sixai-chats-inner-response-row"
                                              id={`sixai-chats-inner-response-${keys}-${rekey}`}
                                              dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(
                                                  marked(
                                                    typeof res === "string"
                                                      ? res
                                                      : ""
                                                  )
                                                ),
                                              }}
                                            ></div>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : (
                                  <div className="loader">
                                    <img src={loadergif} alt="" />
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                </div>
              ) : null}

              {/* 6ix ai shared_friends and genrate response button */}
              {!hidechats ? (
                <div className="sixai-searchbox">
                  {chatcontent?.length ? (
                    <div className="sixai-searchbox-regenrate d-flex justify-content-center">
                      {responseProcessLevel === 1 ? null : (
                        <>
                          {!reading ? (
                            !user_loggedein ? (
                              <div className="login-block">
                                <p>
                                  Login to copy, share and use other features of
                                  6ix AI
                                </p>
                                <Button onClick={handleLogin}>
                                  Login/Register
                                </Button>
                              </div>
                            ) : user_loggedein && subscriptionUpgrade === 0 ? (
                              <div className="login-block">
                                <p>Upgrade to access our most powerful model</p>
                                <Button
                                  onClick={() => setOpenSubscriptionPopup(true)}
                                >
                                  UPGRADE
                                </Button>
                              </div>
                            ) : null
                          ) : (
                            <button
                              className={
                                showStopbtn
                                  ? "share-btn d-flex justify-content-center align-item-center"
                                  : "d-none "
                              }
                              onClick={handleAbortClick}
                            >
                              <i
                                className="fa fa-square-o"
                                aria-hidden="true"
                              ></i>
                              Stop generating
                            </button>
                          )}
                        </>
                      )}
                    </div>
                  ) : null}
                  {/* 6ix ai search_input */}

                  <div
                    className="sixai-searchbox-inner"
                    style={{ height: "auto" }}
                  >
                    <label
                      title={`Add ${
                        selectedTopic?.searchType
                          ? selectedTopic?.searchType === "CSV"
                            ? "CSV"
                            : "DOC, DOCX, PDF"
                          : "CSV, DOC, DOCX, PDF"
                      }`}
                      onClick={() => {
                        if (!user_loggedein) {
                          handleLogin();
                        }
                      }}
                      className="sixai-searchbox-attachment"
                    >
                      <img src={attachmentIcon} alt="attachmentIcon" />
                      {user_loggedein ? (
                        <input
                          type="file"
                          className="sixai-searchbox-attachment-input"
                          accept={
                            selectedTopic?.searchType
                              ? selectedTopic?.searchType === "CSV"
                                ? ".CSV"
                                : ".DOC,.DOCX,.PDF"
                              : ".CSV,.DOC,.DOCX,.PDF"
                          }
                          onChange={onSelectDataFrame}
                        />
                      ) : null}
                    </label>
                    <TextareaAutosize
                      maxRows={10}
                      className={`sixai-searchbox-input ${
                        !selectedTopic?.searchType
                          ? "sixai-searchbox-input-withSelect"
                          : ""
                      }`}
                      placeholder="Enter your request..."
                      disabled={reading}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={(e) =>
                        e.which === 13 && !e.shiftKey && handleSubmit(e)
                      }
                    />
                    {!selectedTopic?.searchType ? (
                      <select
                        className="sixai-searchbox-input-select"
                        defaultValue={searchBy}
                        onChange={onChangeSearchBy}
                      >
                        <option value="" disabled>
                          Search By
                        </option>
                        <option value="ai">AI</option>
                        <option value="internet">Internet</option>
                      </select>
                    ) : null}
                    {/* <Select
                      options={serachOptions}
                      className="sixai-searchbox-input-select"
                      defaultValue={searchBy}
                      isSearchable={false}
                      onChange={onChangeSearchBy}
                    /> */}
                    {regenerateMessage ||
                      (reading && (
                        <div className="creating-msg">
                          <p>AI is thinking...</p>
                        </div>
                      ))}
                    {hideicon && responseProcessLevel > 0 ? (
                      <div className="creating-msg">
                        <p>AI is thinking...</p>
                        {responseProcessLevel === 2 ? (
                          <button
                            id="stop-generating-btn"
                            className="stop"
                            onClick={() => {
                              setResponseProcessLevel(4);
                            }}
                          >
                            STOP
                          </button>
                        ) : null}
                      </div>
                    ) : (
                      <img
                        src={send}
                        id="send-btn"
                        alt="send"
                        className="sixai-searchbox-sendimg"
                        ref={search_button}
                        onClick={() => {
                          getallmessages();
                          setCount(count + 1);
                          scroll();
                        }}
                      />
                    )}
                  </div>
                  {selectedTopic?.dataFrame ? (
                    <div className="sixai-searchbox-bottom">
                      {selectedTopic?.dataFrame?.length}{" "}
                      {selectedTopic?.searchType || "File"} Added
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>

            {/* Shared with friends popup */}
            {popup ? (
              <Sharedpopup
                popup={popup}
                setPopup={setPopup}
                setEmailsid={setEmailsid}
                setSubmit={setSubmit}
              />
            ) : null}
            <Deletepopup
              delete_popup={delete_popup}
              setDelete_popup={setDelete_popup}
              calldelete={
                withtopic === "deletetopic"
                  ? deleteTopics
                  : withtopic === "cleartopic"
                  ? clearTopics
                  : withtopic === "deleteshared"
                  ? deleteSharedTopics
                  : withtopic === "clearshared"
                  ? clearsharedconversation
                  : ""
              }
            />
            {openSubscriptionPopup ? (
              <SubscriptionPopup togglePopup={setOpenSubscriptionPopup} />
            ) : null}

            {/* success message of shared wih friends */}
            <div
              className={
                submit
                  ? "d-flex sucess-popup align-item-center"
                  : "d-flex sucess-popup align-item-center showpopup"
              }
            >
              <img src={right_icon} alt="" />
              Conversation shared with your friend successfully!
            </div>
          </div>
          <div
            className={mobSidebar ? "d-none" : "sixai-overlay"}
            onClick={() => setMobSidebar(true)}
          ></div>
        </div>
      </div>
    </>
  );
};
export default Chatgpt;
