export const exportResponse = (text: any) => {
  const fileData = `Question: ${text.question}\nAnswer:\n ${text.answer}`;
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "response.txt";
  link.href = url;
  link.click();
};

export const exportWholeChat = (chat: any) => {
  const fileData = chat.questions.map(
    (question: any, index: number) =>
      `Question: ${question.content}\nAnswer:\n ${chat.answers[index]}\n`
  );
  const blob = new Blob([fileData], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = "response.txt";
  link.href = url;
  link.click();
};
