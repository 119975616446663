import cross_icon from "../../../assets/images/Cross_icon.svg";
import "./subscriptionPopup.scss";

export function SubscriptionPopup(props) {
  const { togglePopup, dropOut } = props;

  return (
    <>
      <div
        align="center"
        direction="column"
        className="sixAIPopup sixAIPopup-md subscriptionPopup "
        onClick={() => {
          if (dropOut) {
            togglePopup(false);
          }
        }}
      >
        <div
          className="sixAIPopup-inner"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <>
            <div className="sixAIPopup-header">
              <div className="sixAIPopup-header-heading">
                Upgrade to the 6ix AI Silver plan for the ability to upload and
                chat with your documents!
              </div>
            </div>

            <div
              className="sixAIPopup-close"
              onClick={() => {
                togglePopup(false);
              }}
            >
              <img className="popup-cross_icon" src={cross_icon} alt="" />
            </div>

            <div className="sixAIPopup-content subscriptionPopup-content">
              <p>
                Meet your new personal data analyst. Upload a CSV and start
                asking questions. It is an Ask Me Anything for Spreadsheets.
              </p>
              <p>
                Chat like how you normally would, but with your CSV files, and
                get answers from you AI-powered personal data analyst!
              </p>
              <p>
                By upgrading to the 6ix AI Silver Plan, you'll enjoy a host of
                benefits, including enhanced data manipulation, better insights,
                and a smoother user experience. Don't miss out on this
                opportunity to take your productivity to the next level!
              </p>
              <p>
                Click the button below to upgrade your account and gain
                immediate access:
              </p>
              <div className="text-center">
                <a
                  target="_blank"
                  onClick={() => {
                    togglePopup(false);
                  }}
                  className="btn-gold subscriptionPopup-btn"
                  href="https://buy.stripe.com/00gg0M5vx3tY6oo4gl"
                  rel="noreferrer"
                >
                  Upgrade Now
                </a>
              </div>
              <p className="text-center">
                🚀 Upgrade now to propel your data analysis to new heights!
              </p>
            </div>
          </>
        </div>
      </div>
    </>
  );
}
