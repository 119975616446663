import cross_icon from "../../../assets/images/Cross_icon.svg";
import { useState, useRef } from "react";
import TagsInput from "react-tagsinput";

import "react-tagsinput/react-tagsinput.css";

const Sharedpopup = (props) => {
  const { popup, setPopup, setSubmit, setEmailsid } = props;
  const [tags, setTags] = useState([]);
  const emailTagesInput = useRef();

  const timeout = () => {
    setTimeout(() => {
      setSubmit(false);
    }, 2000);
  };

  const handleChange = (tags) => {
    setTags(tags);
    setEmailsid(tags);
  };

  const emailrequired = () => {
    setTags([]);
    if (!tags[0] && !emailTagesInput?.current?.state?.tag) {
      setEmailsid(tags);
      setPopup(true);
    } else {
      if (!tags[0] && emailTagesInput?.current?.state?.tag) {
        setEmailsid([emailTagesInput?.current?.state?.tag]);
      }
      setPopup(false);
      setSubmit(true);
    }
  };

  return (
    <>
      <div
        className={
          popup
            ? "sixai-sharedbtn_popup d-flex align-item-center"
            : "sixai-sharedbtn_popup d-flex  align-item-center d-none"
        }
      >
        <h4> Share with friends</h4>
        <img
          className="popup-cross_icon"
          src={cross_icon}
          alt=""
          onClick={() => setPopup(false)}
        />

        <div className="sixai-sharedbtn_popup-email  d-flex">
          <span>Enter one or more emails</span>

          <div className="email-input">
            <TagsInput
              ref={emailTagesInput}
              addKeys={[188, 9, 13, 49, 32]}
              value={tags}
              inputProps={{
                placeholder: "Emails can be entered here, then press share",
              }}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </div>
        </div>
        <div className="sixai-sharedbtn_popup-btn d-flex">
          <button
            className="btn-grey btn-cancel"
            onClick={() => setPopup(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn-gold btn-submit"
            onClick={() => {
              timeout();
              emailrequired();
            }}
          >
            Share
          </button>
        </div>
      </div>
    </>
  );
};
export default Sharedpopup;
