// import Account from "pages/Account";
import Chatgpt from "pages/Chatgpt/Chatgpt";
import Login from "pages/Login";
import { Route, Routes } from "react-router-dom";
export const AppRouter = () => (
  <Routes>
    <Route path="/" element={<Chatgpt />} />
    <Route path="/callback" element={<Login />} />
  </Routes>
);
