import React from "react";
export function Plus() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.002 11.9997C20.002 12.5531 19.5533 13.0017 19 13.0017L4.99995 13.0017C4.44656 13.0017 3.99795 12.5531 3.99795 11.9997C3.99795 11.4463 4.44656 10.9977 4.99995 10.9977L19 10.9977C19.5533 10.9977 20.002 11.4463 20.002 11.9997Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.99817C12.5533 3.99817 13.002 4.44678 13.002 5.00017L13.002 19.0002C13.002 19.5536 12.5533 20.0022 12 20.0022C11.4466 20.0022 10.998 19.5536 10.998 19.0002L10.998 5.00017C10.998 4.44678 11.4466 3.99817 12 3.99817Z"
        fill="white"
      />
    </svg>
  );
}
export default Plus;
