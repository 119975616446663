import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  GoogleAuthProvider,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithCredential,
  signInWithEmailLink,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { CallApiWithoutAuth, CallApi } from "api/apis";
import { toast } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyA7yb0AANWCaBiIDk5kpH-lyQeHeOtcLgM",
  authDomain: "authentication.6ix.com",
  projectId: "six-events",
  storageBucket: "six-events.appspot.com",
  messagingSenderId: "215382211252",
  appId: "1:215382211252:web:b3bbf8418e33745faf75e1",
  measurementId: "G-YGEEQET0LL",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
getAnalytics(app);
const googleProvider = new GoogleAuthProvider({
  prompt: "select_account",
});

const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
    if (auth.currentUser) {
      const user = auth.currentUser;
      const DATA = {
        tokenID: user.accessToken,
        email: user.email,
      };

      let data = await CallApiWithoutAuth(DATA, `api/v1/auth/Verification`);

      if (data.code === 200) {
        let d = data.data;
        d["uid"] = DATA?.uid;
        toast("You are now logged in");
        return { code: 200, data: d };
      } else {
        if (data.errors) {
          for (const property in data.errors) {
            toast(
              data.errors[property]?.[0] || "Error : Something went wrong!"
            );
          }
        } else {
          toast(data?.message || "Error : Something went wrong!");
        }
        return false;
      }
    } else {
      toast("Error:  Login faild");
      return false;
    }
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};

const signInWithCredentialResponse = async (response) => {
  if (response) {
    try {
      const cred = GoogleAuthProvider.credential(response.credential);
      await signInWithCredential(auth, cred);
      if (auth.currentUser) {
        const user = auth.currentUser;
        const DATA = {
          tokenID: user.accessToken,
          email: user.email,
        };

        let data = await CallApiWithoutAuth(DATA, `api/v1/auth/Verification`);
        if (data.code === 200) {
          let d = data.data;
          d["uid"] = DATA?.uid;
          toast("You are now logged in");
          return { code: 200, data: d };
        } else {
          for (const property in data.errors) {
            toast(
              data.errors[property]?.[0] || "Error : Something went wrong!"
            );
          }
          return false;
        }
      } else {
        toast("Error:  Login faild");
        return false;
      }
    } catch (err) {
      toast(errorSplit(err.message));
      return false;
    }
  } else {
    toast(errorSplit("Something went wrong try again"));
    return false;
  }
};

const signInWithUserEmailLink = async (email) => {
  try {
    let url = window.location.href;
    url = url.match(/\?./)
      ? url + "&ref_l_email=" + email
      : url + "?ref_l_email=" + email;

    const actionCodeSettings = {
      url: url,
      handleCodeInApp: true,
    };

    return sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        toast({
          title: "An email with the link has been sent to " + email,
        });
        return "An email with the link has been sent to " + email;
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } catch (err) {
    toast(errorSplit(err.message));
    return false;
  }
};
const signInWithUserEmailLinkVerify = (email) => {
  if (isSignInWithEmailLink(auth, window.location.href)) {
    if (!email) {
      email = window.prompt("Please provide your email for confirmation");
    }

    return signInWithEmailLink(auth, email, window.location.href)
      .then(async (result) => {
        if (auth.currentUser) {
          const user = auth.currentUser;
          const DATA = {
            tokenID: user.accessToken,
            email: user.email,
          };
          let data = await CallApiWithoutAuth(DATA, `api/v1/auth/Verification`);
          if (data.code === 200) {
            let d = data.data;
            d["uid"] = DATA?.uid;
            toast("You are now logged in");
            return { code: 200, data: d };
          } else {
            if (data.errors) {
              for (const property in data.errors) {
                toast(
                  data.errors[property]?.[0] || "Error : Something went wrong!"
                );
              }
            } else {
              toast(data.message);
            }
            return false;
          }
        } else {
          toast("Error:  Login faild");
          return false;
        }
      })
      .catch((err) => {
        toast(errorSplit(err.message));
        return false;
      });
  } else {
    toast("Error:  Something went wrong");
    return false;
  }
};

const userLogout = (user) => {
  signOut(auth);
  if (user?.userId) {
    CallApi({ userId: user?.userId, org: user?.org?.id }, `api/v1/auth/logout`);
  }
};

const errorSplit = (error) => {
  if (error) {
    if (error.indexOf("/") > -1) {
      error = error.split("/")[1];
      if (error.indexOf(")") > -1) {
        error = error.split(")")[0];
      }
      error = "Error : " + error;
    } else if (error.indexOf("Firebase:") > -1) {
      error = error.split("Firebase:")[1];
    }
  }
  return error;
};

export {
  auth,
  signInWithGoogle,
  signInWithUserEmailLink,
  signInWithUserEmailLinkVerify,
  userLogout,
  signInWithCredentialResponse,
};
