import axios from "axios";
import { API_URL } from "common/constants";
import { GET_USER_AUTH_TOKEN } from "common/auth";
export const CallApi = function (querystring = "", Url = "", contentType = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: "post",
      url: API_URL + Url,
      headers: {
        "Content-Type": contentType || "application/json",
        Authorization: GET_USER_AUTH_TOKEN(),
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        resolve(response?.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
export const CallApiWithoutAuth = function (querystring = "", Url = "") {
  return new Promise(async (resolve, reject) => {
    var data = querystring;
    var config = {
      method: "post",
      url: API_URL + Url,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        resolve(response?.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
