import React from "react";
export function MenuIcon(props) {
  return props?.close ? (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" fill="#131313" />
      <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#E3C050" />
      <path
        d="M14.9141 14.9129L25.0964 25.0952"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.9141 25.0941L25.0964 14.9118"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.25 13.4998H26.75"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 8.99988H26.75"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 18.0002H26.75"
        stroke="#C0C0C0"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.24121 28.3906H8.40332L10.3467 33.7422L12.2852 28.3906H13.4521L10.8105 35.5H9.87793L7.24121 28.3906ZM6.65527 28.3906H7.79297L7.99805 33.3076V35.5H6.65527V28.3906ZM12.8955 28.3906H14.0381V35.5H12.6953V33.3076L12.8955 28.3906ZM20.1172 34.4453V35.5H16.3379V34.4453H20.1172ZM16.748 28.3906V35.5H15.4053V28.3906H16.748ZM19.624 31.3252V32.3555H16.3379V31.3252H19.624ZM20.1025 28.3906V29.4502H16.3379V28.3906H20.1025ZM26.7627 28.3906V35.5H25.415L22.3926 30.5684V35.5H21.0498V28.3906H22.3926L25.4248 33.3271V28.3906H26.7627ZM32.085 28.3906H33.4277V33.1123C33.4277 33.6559 33.3105 34.1133 33.0762 34.4844C32.8418 34.8522 32.5228 35.1305 32.1191 35.3193C31.7155 35.5049 31.2565 35.5977 30.7422 35.5977C30.2214 35.5977 29.7575 35.5049 29.3506 35.3193C28.9437 35.1305 28.6247 34.8522 28.3936 34.4844C28.1624 34.1133 28.0469 33.6559 28.0469 33.1123V28.3906H29.3896V33.1123C29.3896 33.4443 29.445 33.7161 29.5557 33.9277C29.6663 34.1361 29.8226 34.2907 30.0244 34.3916C30.2262 34.4925 30.4655 34.543 30.7422 34.543C31.0221 34.543 31.2614 34.4925 31.46 34.3916C31.6618 34.2907 31.8164 34.1361 31.9238 33.9277C32.0312 33.7161 32.085 33.4443 32.085 33.1123V28.3906Z"
        fill="#C0C0C0"
      />
    </svg>
  );
}
export default MenuIcon;
