import cross_icon from "../../../assets/images/Cross_icon.svg";
const deletepopup = (props) => {
  const { delete_popup, setDelete_popup, calldelete } = props;
  return (
    <>
      <div
        className={
          delete_popup
            ? "sixai-sharedbtn_popup delete_popup d-flex align-item-center"
            : "sixai-sharedbtn_popup d-flex align-item-center d-none"
        }
      >
        <h4> Do You really Want to delete?</h4>
        <img
          className="popup-cross_icon"
          src={cross_icon}
          alt=""
          onClick={() => setDelete_popup()}
        />
        <div className="sixai-sharedbtn_popup-btn d-flex">
          <button
            className="btn-grey btn-cancel"
            onClick={() => setDelete_popup()}
          >
            Cancel
          </button>
          <button
            className="btn-gold btn-submit"
            onClick={() => {
              setDelete_popup();
              calldelete();
            }}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};
export default deletepopup;
